import { Vector3, MathUtils, Vector2, Raycaster } from 'three';
import { commonConstants, distancePenaltyConstants } from '../const_params';
import { projectPointToImage } from '../lumina.common.logic';
import { lin_mult } from '../utils/threeUtils';

const {
  caries_detection_score_threshold,
  max_dist_from_cam_to_img_cen_on_surf,
  angie_max_distance_between_pr_pt_and_cam_mm,
  max_distance_between_image_and_view_projections,
  roiInflateRates,
  min_image_capture_height,
  max_image_capture_height,
  angleDiffVariantsArr,
  checkLoupeVisibility,
} = commonConstants;

const {
  distance_compliance_coefficient,
  angle_compliance_coefficient,
  caries_detection_compliance_coefficient,
} = distancePenaltyConstants;

const isCariesDetectionScoreValid = (image_idx, images_meta_data) => {
  const invalidCariesScore = -1;
  const epsilon = Number.EPSILON;
  const { caries_detection_score } = images_meta_data[image_idx];
  return Math.abs(caries_detection_score - invalidCariesScore) > epsilon;
};

const isFrontFace = (intersect, raycaster) => {
  const { face, object } = intersect;
  const normal = face.normal
    .clone()
    .applyMatrix4(object.matrixWorld)
    .normalize();
  const rayDirection = raycaster.ray.direction;
  const isFrontFace = normal.dot(rayDirection) < 0;
  return isFrontFace;
};

const project = (cam_to_abs_tx, imageViewDirection, pointInProjectionCs, mesh) => {
  const ray_starting_point = new Vector3(pointInProjectionCs.x, pointInProjectionCs.y, 0).applyMatrix4(cam_to_abs_tx);
  const rayCaster = new Raycaster(ray_starting_point, imageViewDirection.normalize(), 0, Infinity);
  rayCaster.firstHitOnly = true;
  const intersects = rayCaster.intersectObjects([mesh]);
  return intersects.reduce((acc, intersect) => {
    if (intersect) {
      intersect.isFrontFace = isFrontFace(intersect, rayCaster);
    }
    return acc;
  }, intersects);
};

const checkLoupeVisibilityForImage = (cam_to_abs_tx, point, mesh) => {
  const abs_to_cam_tx = cam_to_abs_tx.clone().invert();
  const pointInProjectionCs = point.clone().applyMatrix4(abs_to_cam_tx);
  // If a point is behind the view, it's not visible
  if (pointInProjectionCs.z < 0) {
    return false;
  }

  const imageViewDirection = lin_mult(new Vector3(0, 0, 1), cam_to_abs_tx);

  const intersects = project(cam_to_abs_tx, imageViewDirection, pointInProjectionCs, mesh);
  if (intersects.length === 0) {
    return true;
  }

  const { isFrontFace } = intersects[0];
  const distanceBetweenImageAndViewProjections = new Vector3().subVectors(intersects[0].point, point).length();

  return isFrontFace && distanceBetweenImageAndViewProjections < max_distance_between_image_and_view_projections;
};

const computePositionValueAndDirection = ({
  meshes,
  jawName,
  image_idx,
  intersect,
  view_direction,
  images_meta_data,
  max_angle_difference,
  roiInflateRate,
}) => {
  const intersect_pt = intersect.point;
  const currentImageData = images_meta_data[image_idx];
  const {
    scan_role,
    was_cam_projected,
    img_cen_on_surf_pt,
    camera_pt,
    camera_dir,
    rect_of_image,
    caries_detection_score,
    camera_to_pixel,
    K_vector,
    P_vector,
    dist_from_cam_to_surf,
    cam_to_abs_tx,
    caries_reference_points,
    worldToCamTx,
  } = currentImageData;

  if (scan_role !== jawName) return;
  // TODO uncoment when ALGO team will implement caries detection -2 score
  // if (caries_detection_score === -2) return;

  // 1. Constraint: the distance between loupe position and image center projected on surface should not differ too much.
  // Although similar criteria is utilised below, they rely on the fact that projected image center is actually visible from
  // corresponding camera. However, it might happen that image center on surface is not visible from camera, but belongs to the
  // region of interest. This is due to the fact the we do not apply rasterization/ray tracing, while projection algorithm
  if (was_cam_projected) {
    const intersectPointToImgCenterVectorLength = new Vector3().subVectors(intersect_pt, img_cen_on_surf_pt).length();
    if (intersectPointToImgCenterVectorLength > max_dist_from_cam_to_img_cen_on_surf) return;
  } else {
    const intersectPointToCameraPointVectorLength = new Vector3().subVectors(intersect_pt, camera_pt).length();
    if (intersectPointToCameraPointVectorLength > angie_max_distance_between_pr_pt_and_cam_mm) return;
  }

  // 2. Constraint capture height: selected image shouldn't be too close to the surface
  if (!(dist_from_cam_to_surf >= min_image_capture_height && dist_from_cam_to_surf <= max_image_capture_height)) return;

  // 3. Constraint Angle between view direction and camera direction should not differ too much
  const cameraDirectionVector = new Vector3().copy(camera_dir);
  const angle_diff = cameraDirectionVector.angleTo(view_direction);

  if (angle_diff > MathUtils.degToRad(max_angle_difference)) return;

  // 4. Constraint: loupe should actually belong to the region of interest
  const projected2DPointOnImage = projectPointToImage(intersect, worldToCamTx, camera_to_pixel, K_vector, P_vector);

  const roiInflated = rect_of_image.inflate(roiInflateRate);
  if (!roiInflated.includesPoint(new Vector2(projected2DPointOnImage.x, projected2DPointOnImage.y))) return;

  // 5. Constraint Loupe should display only physically visible images
  if (checkLoupeVisibility) {
    const mesh = meshes.find((mesh) => mesh.name === jawName);
    if (mesh) {
      const isLoupeVisibleOnSurface = checkLoupeVisibilityForImage(
        cam_to_abs_tx,
        intersect_pt,
        meshes.find((mesh) => mesh.name === jawName)
      );

      if (!isLoupeVisibleOnSurface) return;
    }
  }

  // 6. Compute compliance coefficient with current view. For the second round select only those images that pass a predefined threshold
  const roi_center_pt = roiInflated.center();
  const distance_px = new Vector2().subVectors(roi_center_pt, projected2DPointOnImage).length();

  // distance
  const distance_compliance =
    distance_px / new Vector2().subVectors(roiInflated.corner_max(), roiInflated.center()).length();
  const distance_penalty = distance_compliance * distance_compliance_coefficient;

  // angle
  const angle_compliance = angle_diff / MathUtils.degToRad(max_angle_difference);
  const angle_penalty = angle_compliance * angle_compliance_coefficient;

  // caries
  let caries_score = caries_detection_score || 0;
  if (caries_reference_points.length > 0) {
    const { score } = caries_reference_points.reduce(
      (acc, { point, score }) => {
        const dist = new Vector3().subVectors(point, intersect_pt).length();
        return dist < acc.dist ? { dist, score } : acc;
      },
      { dist: Number.MAX_VALUE, score: caries_score }
    );
    caries_score = score;
  }
  const caries_detection_score_is_invalid = caries_score === -1;
  const caries_detection_compliance = caries_detection_score_is_invalid ? 0 : 1 - caries_score;
  const caries_detection_penalty =
    caries_detection_compliance * caries_detection_compliance_coefficient +
    // If caries detection score threshold is not satisfied, caries detection penalty gets huge boost...
    (caries_score < caries_detection_score_threshold ? 10 : 0) +
    // ... and another one if image has invalid caries detection score (which means that image contains large reflections)
    (caries_detection_score_is_invalid ? 10 : 0);

  const penalty_value = angle_penalty + distance_penalty + caries_detection_penalty;

  return {
    img_idx: image_idx,
    penalty: penalty_value,
    selected2DPointOnImage: projected2DPointOnImage,
  };
};

export const selectBestMatchImageByPenalty = (
  meshes,
  view_direction,
  jawName,
  intersect,
  currentActiveJaw,
  images_meta_data
) => {
  // Part 1: compute general compliance coefficient
  for (let angleDiffVariants = 0; angleDiffVariants < angleDiffVariantsArr.length; ++angleDiffVariants) {
    for (let index = 0; index < roiInflateRates.length; index++) {
      const selectedImage = selectBestMatch(
        meshes,
        view_direction,
        jawName,
        intersect,
        currentActiveJaw,
        images_meta_data,
        roiInflateRates[index],
        angleDiffVariantsArr[angleDiffVariants]
      );

      if (selectedImage.img_idx === -1) break;

      if (isCariesDetectionScoreValid(selectedImage.img_idx, images_meta_data)) {
        return {
          img_idx: selectedImage.img_idx,
          image: currentActiveJaw[selectedImage.img_idx],
          selected2DPointOnImage: selectedImage.selected2DPointOnImage,
        };
      }
    }
  }

  return {
    img_idx: -1,
    image: null,
    selected2DPointOnImage: new Vector2(0, 0),
  };
};

const selectBestMatch = (
  meshes,
  view_direction,
  jawName,
  intersect,
  currentActiveJaw,
  images_meta_data,
  roiInflateRate,
  maxAngleDiff
) => {
  let minImagePenalty = Number.MAX_VALUE;
  const selectedImage = { img_idx: -1, selected2DPointOnImage: new Vector2(0, 0) };

  for (let image_idx = 0; image_idx < currentActiveJaw.length; ++image_idx) {
    const positionValueAndDirection = computePositionValueAndDirection({
      meshes,
      jawName,
      image_idx,
      intersect,
      view_direction,
      images_meta_data,
      max_angle_difference: maxAngleDiff,
      roiInflateRate,
    });

    if (!positionValueAndDirection) continue;

    if (positionValueAndDirection.penalty < minImagePenalty) {
      selectedImage.img_idx = positionValueAndDirection.img_idx;
      selectedImage.penalty = positionValueAndDirection.penalty;
      selectedImage.selected2DPointOnImage = positionValueAndDirection.selected2DPointOnImage;
      minImagePenalty = positionValueAndDirection.penalty;
    }
  }

  return selectedImage;
};
