import React, { useState, useEffect, Fragment, useCallback, useMemo } from 'react';
import { noop, get, set } from 'lodash';
import { logToTimber } from '@web-3d-tool/shared-logic';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ContrastBrihgtnessSliders from '../sliders/contrast-brightness-sliders';
import styles from './drawer2DRotation.module.css';

const Drawer2DRotation = (props) => {
  const {
    width,
    isVertical,
    sourcesArr,
    slidersValues,
    isEnlargedFrame,
    isDrawerDisabled,
    setslidersValues,
    handleChangeContrast,
    handleChangeBrightness,
    containerSize,
    shouldRenderPinButton,
    isModelCompared,
    isAOHS,
    sdk,
    modelType,
  } = props;

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [shouldShowDrawer, setShouldShowDrawer] = useState(false);
  const { length } = sourcesArr;
  const isSingleImageShown = isEnlargedFrame || length === 1;

  // memos
  const slidersInlineStyle = useMemo(
    () => ({
      height: isVertical ? ` ${isSingleImageShown ? 70 : 100 / length}%` : '33px',
      margin: 'auto',
      padding: '15px 5px',
      transition: 'height 0.5s ease-in-out',
    }),
    [isSingleImageShown, isVertical, length]
  );

  const splitSide = useMemo(() => {
    const threeObjects = sdk.getThreeJSObjects()[modelType];
    const visibilityObject = sdk.getPluginParameters()?.pluginsManager5d?.niriLogic?.getVisibilityObject();
    const jaw = threeObjects[visibilityObject[0] || 'lower_jaw'];
    const { splitSide } = (Object.keys(jaw).length > 0 && jaw) || { splitSide: 0 };
    return splitSide;
  }, [sdk, modelType]);

  const pinButtonMargin = useMemo(() => {
    if (!shouldRenderPinButton) return 0;
    return shouldRenderPinButton && !isModelCompared ? 68 : 68 * splitSide;
  }, [shouldRenderPinButton, isModelCompared, splitSide]);

  // callbacks
  const toggleDrawer = useCallback(() => {
    logToTimber({
      timberData: {
        eventName: `${isDrawerDisabled ? 'Opening' : 'Closing'} drawer`,
        action: 'click',
        module: 'UI-actions',
        type: 'button',
      },
    });
    if (isDrawerDisabled) return;
    setIsDrawerOpen(!isDrawerOpen);
  }, [isDrawerDisabled, isDrawerOpen]);

  const drawerCalculatedHeight = useMemo(() => {
    return isAOHS ? 135 : 195;
  }, [isAOHS]);

  // effects
  useEffect(() => {
    sourcesArr.length === 0 && setIsDrawerOpen(false);
  }, [sourcesArr.length]);

  useEffect(() => {
    const shouldShow = sourcesArr.every((source) => !source.isPlaceHolderIcon);
    setShouldShowDrawer(shouldShow);
  }, [sourcesArr]);

  return (
    shouldShowDrawer && (
      <div
        style={{
          '--right': `${containerSize.drawerRight}`,
          '--pin-button-margin': `${pinButtonMargin}px`,
        }}
        className={classNames(styles.container)}
      >
        <div
          style={{
            '--height': `${drawerCalculatedHeight}px`,
          }}
          className={classNames(styles.slidersContainer, {
            [styles.slidersContainer_open]: isDrawerOpen,
          })}
        >
          {sourcesArr
            .filter((source) => (isEnlargedFrame ? source.type === isEnlargedFrame : source))
            .map(({ type }, index) => (
              <Fragment key={index}>
                <ContrastBrihgtnessSliders
                  width={width}
                  defaultBrightness={get(slidersValues, `${type}-image.brightness`)}
                  defaultContrast={get(slidersValues, `${type}-image.contrast`)}
                  isVertical={isVertical}
                  type={type}
                  dataBiType={type}
                  onBrightnessChanged={(val) =>
                    setslidersValues((slidersValues) => set(slidersValues, `${type}-image.brightness`, val))
                  }
                  onContrastChanged={(val) => {
                    setslidersValues((slidersValues) => set(slidersValues, `${type}-image.contrast`, val));
                  }}
                  handleChangeBrightness={handleChangeBrightness}
                  handleChangeContrast={handleChangeContrast}
                  slidersInlineStyle={{
                    ...slidersInlineStyle,
                    ...(!index && sourcesArr.length > 1 && !isEnlargedFrame
                      ? { borderBottom: '1px solid #D9D9D9' }
                      : {}),
                  }}
                />
              </Fragment>
            ))}
        </div>
        <div
          className={classNames(styles.drawer, {
            [styles.drawer_open]: isDrawerOpen,
          })}
          onClick={toggleDrawer}
          data-test-id="drawer"
          id="drawer"
        >
          <i className={classNames(styles.drawer_closed, { [styles.arrow_disabled]: isDrawerDisabled })} />
        </div>
      </div>
    )
  );
};

Drawer2DRotation.defaultProps = {
  isVertical: true,
  isDrawerOpen: false,
  isDrawerDisabled: true,
  isEnlargedFrame: false,
  onContrastChanged: noop,
  onBrightnessChanged: noop,
  handleChangeContrast: noop,
  handleChangeBrightness: noop,
};

Drawer2DRotation.propTypes = {
  /*
   * The images IOC / NIRI enabled count
   */
  sourcesArr: PropTypes.array,
  /*
   * Is the image in enlarged mode
   */
  isEnlargedFrame: PropTypes.bool,
  /*
   * Is the tool is disabled
   */
  isDrawerDisabled: PropTypes.bool,
};

export default Drawer2DRotation;
