/* eslint-disable no-unused-vars */
import {
  UserActionStruct,
  UiNavigationStruct,
  ErrorReportStruct,
  APIReportStruct,
  SpecialStruct,
} from './structs/biEventTypeStructs';
import { BIEventType, BIEventTypeToLogMap } from '../constants';

/**
 * Logs a BI event for a user action.
 * @param {UserActionStruct} BIEventType.UserAction - The user action structure.
 * @returns {Object} An object with a timberData property that includes the userActionStruct parameter under the additionalFields property and the BIEventType.UserAction object under the biEventType property.
 */
const userActionBiLog = ({ ...UserActionStruct }) => {
  return {
    timberData: {
      additionalFields: UserActionStruct,
      biEventType: BIEventTypeToLogMap[BIEventType.UserAction],
    },
  };
};

/**
 * Logs a BI event for a UI navigation.
 * @param {UiNavigationStruct} BIEventType.UiNavigation - The UI navigation structure.
 * @returns {Object} An object with a timberData property that includes the uiNavigationStruct parameter under the additionalFields property and the BIEventType.UiNavigation object under the biEventType property.
 */
const uiNavigationBiLog = ({ ...UiNavigationStruct }) => {
  return {
    timberData: {
      additionalFields: UiNavigationStruct,
      biEventType: BIEventTypeToLogMap[BIEventType.UiNavigation],
    },
  };
};

/**
 * Logs a BI event for an error report.
 * @param {ErrorReportStruct} BIEventType.ErrorReport - The error report structure.
 * @returns {Object} An object with a timberData property that includes the errorReportStruct parameter under the additionalFields property and the BIEventType.ErrorReport object under the biEventType property.
 */
const errorReportBiLog = ({ ...ErrorReportStruct }) => {
  return {
    timberData: {
      additionalFields: ErrorReportStruct,
      biEventType: BIEventTypeToLogMap[BIEventType.ErrorReport],
    },
  };
};

/**
 * Logs a BI event for an API report.
 * @param {APIReportStruct} BIEventType.APIReport - The API report structure.
 * @returns {Object} An object with a timberData property that includes the apiReportStruct parameter under the additionalFields property and the BIEventType.APIReport object under the biEventType property.
 */
const apiReportBiLog = ({ ...APIReportStruct }) => {
  return {
    timberData: {
      additionalFields: APIReportStruct,
      biEventType: BIEventTypeToLogMap[BIEventType.APIReport],
    },
  };
};

/**
 * Logs a BI event for a special event.
 * @param {SpecialStruct} BIEventType.Special - The special structure.
 * @returns {Object} An object with a timberData property that includes the specialStruct parameter under the additionalFields property and the BIEventType.Special object under the biEventType property.
 */
const specialBiLog = ({ ...SpecialStruct }) => {
  return {
    timberData: {
      additionalFields: SpecialStruct,
      biEventType: BIEventTypeToLogMap[BIEventType.Special],
    },
  };
};

/**
 * Logs a BI event for a stage report.
 * @param {StageReportStruct} BIEventType.StageReport - The stage report structure.
 * @returns {Object} An object with a timberData property that includes the stageReportStruct parameter under the additionalFields property and the BIEventType.StageReport object under the biEventType property.
 */
const stageReportBiLog = ({ ...StageReportStruct }) => {
  return {
    timberData: {
      additionalFields: StageReportStruct,
      biEventType: BIEventTypeToLogMap[BIEventType.StageReport],
    },
  };
};

export default {
  userActionBiLog,
  uiNavigationBiLog,
  errorReportBiLog,
  apiReportBiLog,
  specialBiLog,
  stageReportBiLog,
};
