/* eslint-disable no-unused-vars */
import {
  UserActionStruct,
  UiNavigationStruct,
  ErrorReportStruct,
  APIReportStruct,
  SpecialStruct,
} from '../timberLogger';

const BIEventType = {
  /**
   * The user action structure.
   * @type {UserActionStruct}
   */
  UserAction: 'UserActionType',
  /**
   * The UI navigation structure.
   * @type {UiNavigationStruct}
   */
  UiNavigation: 'UiNavigationType',
  /**
   * The error report structure.
   * @type {ErrorReportStruct}
   */
  ErrorReport: 'ErrorReportType',
  /**
   * The API report structure.
   * @type {APIReportStruct}
   */
  APIReport: 'ApiReportType',
  /**
   * The special structure.
   * @type {SpecialStruct}
   */
  Special: 'SpecialType',
  /**
   * The stage report structure.
   * @type {StageReportStruct}
   */
  StageReport: 'StageReportType',
};

const BIEventTypeToLogMap = {
  [BIEventType.UserAction]: 'UserActionType',
  [BIEventType.UiNavigation]: 'UiNavigationType',
  [BIEventType.ErrorReport]: 'ErrorReportType',
  [BIEventType.APIReport]: 'ApiReportType',
  [BIEventType.Special]: 'SpecialType',
  [BIEventType.StageReport]: 'StageReportType',
};
export { BIEventType, BIEventTypeToLogMap };
