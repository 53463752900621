import React, { useEffect, useState, useRef, useCallback, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { utils, timberWrapperService, currentActiveDebugFunctions } from '@web-3d-tool/shared-logic';
import { debounce, noop } from 'lodash';
import classNames from 'classnames';
import PinchZoomPan from './PinchZoomPan/PinchZoomPan';
import defaultStyles from './ImageFrame.module.css';
import styles360 from './ImageFrame360.module.css';
import {
  getCorrectImageSize,
  getMaxMovementFromPointInImageToFrameCenter,
  getPointOnResizedImage,
} from './ImageFrame.logic';

const ImageFrame = memo(
  (props) => {
    const { getIs360HubEnabled, getIsScanOriginLumina } = utils;
    const { debug_image_frame } = currentActiveDebugFunctions || {};

    const {
      width,
      height,
      src,
      brightness,
      contrast,
      closestPhotoObjectParams,
      onChange,
      left,
      top,
      scale,
      id,
      dataBiType,
      isEnlargedFrame,
      type,
      luminaOriginalImageSize,
      elementOriginalImageSize,
      viewer360Align2DImages,
      numberOfItems,
      handleOnImageClick,
      imageZindex,
      EnlargeFrameButton,
      index,
      toggleEnlarge,
      modelType,
      isPinActive,
    } = props;

    // params
    const is360 = getIs360HubEnabled();
    const styles = is360 ? styles360 : defaultStyles;
    const isLuminaScan = getIsScanOriginLumina(modelType);
    const {
      rotationByQuarters,
      rotation,
      shouldTransform,
      selectedPointOnImage,
      imageProjectedCenterDirection,
      roi,
    } = closestPhotoObjectParams;
    const shouldHaveBlurredBackground = isLuminaScan && (!viewer360Align2DImages || isPinActive);
    const runOnce = true;
    const containerStyle = classNames(styles.container, shouldHaveBlurredBackground ? styles.blackHiddenOverlay : '');

    // states
    const [loadingComplete, setLoadingComplete] = useState(false);
    const [imageSize, setImageSize] = useState(null);
    const [bluredImageSrc, setBluredImageSrc] = useState(null);
    const imageRef = useRef(null);
    const imageDimensionsRef = useRef({
      scale,
      top,
      left,
    });
    const [imageSrc, setImageSrc] = useState(src);

    // functions
    const getNewValuesFromEvent = (args) => {
      const { scale: newScale, top: newTop, left: newLeft } = args;
      const { scale: prevScale, top: prevTop, left: prevLeft } = imageDimensionsRef.current;
      const hasNewValuesFromEvent = !(newScale === prevScale && newTop === prevTop && newLeft === prevLeft);

      imageDimensionsRef.current = {
        scale: newScale,
        top: newTop,
        left: newLeft,
      };

      if (hasNewValuesFromEvent) {
        if (newScale !== prevScale) {
          return 'zoom';
        } else {
          return 'pan';
        }
      }
      return '';
    };

    const debouncedHandlePinchZoomPanLog = useCallback(
      debounce((args) => {
        const eventType = getNewValuesFromEvent(args);

        if (eventType) {
          timberWrapperService.timber.shared_ui.imageFrame.imageFrameTimberLog({ eventType, eventSource: dataBiType });
        }
      }, 1000),
      []
    );

    const handlePinchZoomPanChange = (args) => {
      if (onChange) {
        onChange(args);
      }
      debouncedHandlePinchZoomPanLog(args);
    };

    const createBlurredImage = async (imgSrc, width, height) => {
      const img = new Image();
      img.src = imgSrc;

      await new Promise((resolve, reject) => {
        img.onload = resolve;
        img.onerror = reject;
      });

      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const maxPanelSize = Math.max(width, height);

      canvas.width = maxPanelSize;
      canvas.height = maxPanelSize;

      ctx.filter = 'blur(40px) brightness(100%)';
      ctx.drawImage(img, 0, 0, maxPanelSize, maxPanelSize);

      const blurredImage = canvas.toDataURL('image/jpeg', 1.0);
      return blurredImage;
    };

    // memos
    const actualRotation = useMemo(() => {
      if (viewer360Align2DImages && !isPinActive) {
        return rotation;
      }
      return rotationByQuarters;
    }, [isPinActive, rotation, rotationByQuarters, viewer360Align2DImages]);
    const inlineStyle = useMemo(() => {
      const maxPanelSize = isPinActive ? Math.min(width, height) : Math.max(width, height);
      const panelSize = shouldHaveBlurredBackground
        ? { width: maxPanelSize, height: maxPanelSize, borderRadius: isPinActive ? '8px' : 0 }
        : { width, height, borderRadius: isPinActive ? '8px' : 0 };
      return { ...panelSize, ...imageZindex };
    }, [height, imageZindex, isPinActive, shouldHaveBlurredBackground, width]);

    const imageCorrectSizes = useMemo(() => {
      const calcWidth = isPinActive ? inlineStyle.width : width;
      const { width: correctWidth, height: correctHeight } = getCorrectImageSize(
        calcWidth,
        height,
        isEnlargedFrame,
        luminaOriginalImageSize,
        elementOriginalImageSize,
        viewer360Align2DImages,
        shouldHaveBlurredBackground,
        modelType,
        isPinActive
      );
      return { width: correctWidth, height: correctHeight };
    }, [
      isPinActive,
      inlineStyle.width,
      width,
      height,
      isEnlargedFrame,
      luminaOriginalImageSize,
      elementOriginalImageSize,
      viewer360Align2DImages,
      shouldHaveBlurredBackground,
      modelType,
    ]);

    const getTopLeft = useMemo(() => {
      if (isLuminaScan && imageSize && is360 && !viewer360Align2DImages && !shouldHaveBlurredBackground) {
        const getMaxMoveImage = getMaxMovementFromPointInImageToFrameCenter(
          selectedPointOnImage,
          imageSize,
          actualRotation,
          height,
          width,
          type,
          roi
        );
        return { left: getMaxMoveImage.moveLeft, top: getMaxMoveImage.moveTop };
      }

      if ((isLuminaScan && imageSize && !is360) || shouldHaveBlurredBackground) {
        const middleTopPoisition = height / 2 - (imageSize ? imageSize?.height / 2 : 0);
        return { left: 0, top: middleTopPoisition };
      }

      return { top, left };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [height, imageSize, is360, isLuminaScan, left, scale, shouldHaveBlurredBackground, top, viewer360Align2DImages]);

    // effects
    useEffect(() => {
      let isMounted = true;
      const img = new Image();
      img.onload = async () => {
        const imageSrc = debug_image_frame
          ? debug_image_frame(
              img,
              [
                getPointOnResizedImage(selectedPointOnImage, roi, type),
                getPointOnResizedImage(imageProjectedCenterDirection, roi, type),
              ],
              [{ color: 'green' }, { color: 'red' }]
            )
          : src;

        if (isMounted) {
          setImageSrc(imageSrc);
          setLoadingComplete(true);
        }
      };
      img.src = src;

      return () => {
        isMounted = false;
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [src, runOnce, selectedPointOnImage, isPinActive]);

    useEffect(() => {
      if (loadingComplete) {
        const { width, height } = imageCorrectSizes;
        setImageSize({ width, height });
      }
    }, [imageCorrectSizes, loadingComplete, width, height]);

    useEffect(() => {
      if (imageRef.current) imageRef.current.style.filter = `brightness(${brightness}%) contrast(${contrast}%)`;
    }, [brightness, contrast]);

    useEffect(() => {
      const fetchBlurredImage = async () => {
        if (imageSrc && shouldHaveBlurredBackground) {
          setBluredImageSrc(await createBlurredImage(src, inlineStyle.width, inlineStyle.width));
        }
      };
      fetchBlurredImage();
    }, [imageSrc, inlineStyle.width, isEnlargedFrame, shouldHaveBlurredBackground, src]);

    return (
      imageSize && (
        <div style={inlineStyle} className={containerStyle}>
          {shouldHaveBlurredBackground && (
            <div className={styles.backgroundImage} style={{ transform: `rotate(${actualRotation}deg)` }}>
              <img src={bluredImageSrc} alt="blured-selected-img" />
            </div>
          )}
          <PinchZoomPan
            doubleTapBehavior="zoom"
            position="center"
            minScale={1}
            initialScale={1}
            maxScale={10}
            rotation={actualRotation}
            shouldTransform={shouldTransform}
            frameWidth={inlineStyle.width}
            frameHeight={inlineStyle.height}
            onChange={handlePinchZoomPanChange}
            shouldHaveBlurredBackground={shouldHaveBlurredBackground}
            left={getTopLeft.left}
            top={getTopLeft.top}
            scale={scale}
            inversePan={viewer360Align2DImages}
            isEnlargedFrame={isEnlargedFrame}
            isAlign2DImages={is360 && viewer360Align2DImages}
            numberOfItems={numberOfItems}
            isLuminaScan={isLuminaScan}
            type={type}
            isPinActive={isPinActive}
          >
            <img
              className={
                shouldHaveBlurredBackground || !viewer360Align2DImages ? styles.imageFrame : styles.image_frame_360
              }
              onClick={() => (viewer360Align2DImages ? handleOnImageClick(type) : {})}
              alt=""
              ref={imageRef}
              src={imageSrc}
              width={imageSize.width}
              height={imageSize.height}
              data-test-id={id}
              id={id}
            />
            {viewer360Align2DImages && !isPinActive && (
              <EnlargeFrameButton
                is360={is360}
                type={type}
                index={index}
                enlargedImage={isEnlargedFrame}
                handleEnlarge={toggleEnlarge}
                viewer360Align2DImages={viewer360Align2DImages}
              />
            )}
          </PinchZoomPan>
          {isPinActive && (
            <EnlargeFrameButton
              is360={is360}
              type={type}
              index={index}
              enlargedImage={isEnlargedFrame}
              handleEnlarge={toggleEnlarge}
              viewer360Align2DImages={viewer360Align2DImages}
            />
          )}
        </div>
      )
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.src === nextProps.src &&
      prevProps.height === nextProps.height &&
      prevProps.brightness === nextProps.brightness &&
      prevProps.contrast === nextProps.contrast &&
      prevProps.rotation === nextProps.rotation &&
      prevProps.viewer360Align2DImages === nextProps.viewer360Align2DImages &&
      prevProps.imageZindex === nextProps.imageZindex &&
      prevProps.EnlargeFrameButton === nextProps.EnlargeFrameButton &&
      prevProps.toggleEnlarge === nextProps.toggleEnlarge &&
      prevProps.isPinActive === nextProps.isPinActive
    );
  }
);

ImageFrame.defaultProps = {
  width: 200,
  height: 200,
  src: '',
  brightness: 100,
  contrast: 100,
  rotation: 0,
  viewer360Align2DImages: false,
  imageZindex: 1,
  EnlargeFrameButton: {},
  toggleEnlarge: noop,
};

ImageFrame.propTypes = {
  /**
   * The image width
   */
  width: PropTypes.number,
  /**
   * The image width
   */
  height: PropTypes.number,
  /**
   * Image source
   */
  src: PropTypes.string.isRequired,
  /**
   * The number of percentage of brightness apply to the image
   */
  brightness: PropTypes.number,
  /**
   * The number of percentage of contrast apply to the image
   */
  contrast: PropTypes.number,
  /**
   * The rotation angle of the image
   */
  rotation: PropTypes.number,
  /**
   * Callback, fired when pinch, zoom, pan
   */
  onChange: PropTypes.func,
  /**
   * having 360 style
   */
  is360: PropTypes.bool,
};

export default ImageFrame;
