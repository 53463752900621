import { scannerVersionManager } from '../scanner-version-manager/scanner-version-manager';
import { utils } from '../utils';
const requests =
  utils &&
  utils.isScannerHostEnv() &&
  scannerVersionManager &&
  scannerVersionManager.getScannerAssets().scannerRequests;
export const scannerRequests = {
  ...requests,
};
