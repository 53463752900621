import { Vector2 } from 'three';
import { utils } from '@web-3d-tool/shared-logic';

// eslint-disable-next-line no-unused-vars
const convertPointOnImageToResizedImage = (originalPoint, originalSize, newSize) => {
  const new_x = (originalPoint.x / originalSize.width) * newSize.width;
  const new_y = (originalPoint.y / originalSize.height) * newSize.height;
  return { x: new_x, y: new_y };
};

const convertPointOnImageToFrameCoords = (point, frameSize, imageSize) => {
  const x_scale = imageSize.width / frameSize.width || 1;
  const y_scale = imageSize.height / frameSize.height || 1;
  const x_pos = Math.ceil(point.x / x_scale);
  const y_pos = Math.ceil(point.y / y_scale);
  return { x_pos, y_pos };
};

/***
 * Mainly for debug purposes
 */
const getPointOnResizedImage = (point, roi, type) => {
  if (!point) return { x: 0, y: 0 };
  if (type === 'color') {
    return roi ? { x: point.y * 2 - roi.y_min, y: point.x * 2 - roi.x_min } : point;
  }
  return roi ? { x: point.y - roi.y_min, y: point.x - roi.x_min } : point;
};

/***
 * Image croping logic
 */
const getMaxMovementFromPointInImageToFrameCenter = (
  loupePointOnImage,
  imageSize,
  rotation,
  height,
  width,
  type,
  roi
) => {
  const imageMovingRangeLeftPx = -Math.abs(imageSize.width - width);
  const imageMovingRangeTopPx = -Math.abs(imageSize.height - height);
  const frameCenterPt = new Vector2(width / 2, height / 2);

  const pointOnResizedImage = getPointOnResizedImage(loupePointOnImage, roi, type);
  const pointOnImageInFrameCoords = convertPointOnImageToFrameCoords(pointOnResizedImage, { width, height }, imageSize);

  const delta_x = frameCenterPt.x - pointOnImageInFrameCoords.x_pos;
  const delta_y = frameCenterPt.y - pointOnImageInFrameCoords.y_pos;

  const x_adj = -Math.abs(pointOnImageInFrameCoords.x_pos - delta_x) / 2;
  const y_adj = -Math.abs(pointOnImageInFrameCoords.y_pos - delta_y) / 2;

  let y_adj_normalized = 0;
  let x_adj_normalized = 0;

  if (rotation === 180 || rotation === 270) {
    const x_adj_rotation = -Math.abs(imageMovingRangeLeftPx - x_adj);
    const y_adj_rotation = -Math.abs(imageMovingRangeTopPx - y_adj);
    x_adj_normalized = Math.max(x_adj_rotation, imageMovingRangeLeftPx);
    y_adj_normalized = Math.max(y_adj_rotation, imageMovingRangeTopPx);
  } else {
    x_adj_normalized = Math.max(x_adj, imageMovingRangeLeftPx);
    y_adj_normalized = Math.max(y_adj, imageMovingRangeTopPx);
  }

  return { moveLeft: -Math.abs(x_adj_normalized), moveTop: -Math.abs(y_adj_normalized) };
};

const getCorrectImageSize = (
  width,
  height,
  isEnlargedFrame,
  luminaOriginalImageSize,
  elementOriginalImageSize,
  viewer360Align2DImages,
  shouldHaveBlurredBackground,
  modelType,
  isPinActive
) => {
  const imagesDefaultSizes = {
    LUMINA_IMAGE_SIZE: luminaOriginalImageSize,
    ELEMENT_IMAGE_SIZE: elementOriginalImageSize,
    imageHypoSizeCtrlCoeff: 0.8,
    imageEnlargeHypoSizeCtrlCoeff: 0.915,
  };

  const is360hub = utils.getIs360HubEnabled();
  const isLumina = utils.getIsScanOriginLumina(modelType);

  if (is360hub && !isPinActive && (isLumina || viewer360Align2DImages) && !shouldHaveBlurredBackground) {
    const { correctWidth, correctHeight } = viewer360Align2DImages
      ? getCorrectImageSizeForRotationin360(width, height, isEnlargedFrame, imagesDefaultSizes, isLumina)
      : getCorrectImageSizeFor360(height, imagesDefaultSizes);

    return { width: correctWidth, height: correctHeight };
  }

  if ((!is360hub && isLumina) || shouldHaveBlurredBackground) {
    const aspectRatio = imagesDefaultSizes.LUMINA_IMAGE_SIZE.width / imagesDefaultSizes.LUMINA_IMAGE_SIZE.height;
    const size = Math.max(width, height);
    return { width: size, height: size / aspectRatio };
  }

  if (isPinActive && !isLumina) {
    const aspectRatio = imagesDefaultSizes.ELEMENT_IMAGE_SIZE.width / imagesDefaultSizes.ELEMENT_IMAGE_SIZE.height;
    const size = Math.min(width, height);

    const singleFrameHeightSize = size / aspectRatio;
    if (singleFrameHeightSize > (size * 2) / 2) {
      const newFrameHeight = size - (singleFrameHeightSize - size);
      return { width: newFrameHeight, height: newFrameHeight / aspectRatio };
    }

    return { width: size, height: size / aspectRatio };
  }

  const { width: imgWidth, height: imgHeight } = imagesDefaultSizes.ELEMENT_IMAGE_SIZE;
  const { width: correctWidth, height: correctHeight } = getCorrectImageSizeForRTH(width, height, imgWidth, imgHeight);

  return { width: correctWidth, height: correctHeight };
};

const getCorrectImageSizeForRotationin360 = (width, height, isEnlargedFrame, imagesDefaultSizes, isLumina) => {
  const {
    LUMINA_IMAGE_SIZE,
    ELEMENT_IMAGE_SIZE,
    imageHypoSizeCtrlCoeff,
    imageEnlargeHypoSizeCtrlCoeff,
  } = imagesDefaultSizes;
  const { width: naturalWidth, height: naturalHeight } = isLumina ? LUMINA_IMAGE_SIZE : ELEMENT_IMAGE_SIZE;
  const naturalImageHypotenuse = Math.sqrt(naturalWidth ** 2 + naturalHeight ** 2);

  const maxFrameSide = Math.max(width, height);
  const isFrameLarger = maxFrameSide >= naturalImageHypotenuse;

  if (isFrameLarger) {
    return { correctWidth: naturalWidth, correctHeight: naturalHeight };
  } else {
    const minSide = Math.min(width, height);
    const imageSizeCtrl =
      naturalImageHypotenuse > maxFrameSide
        ? isEnlargedFrame
          ? imageEnlargeHypoSizeCtrlCoeff
          : imageHypoSizeCtrlCoeff
        : 1;

    const imagesSizeFactor = isLumina ? 0.85 : 0.8;
    const correctWidth = minSide * (naturalWidth / (naturalImageHypotenuse * imageSizeCtrl)) * imagesSizeFactor;
    const correctHeight = minSide * (naturalHeight / (naturalImageHypotenuse * imageSizeCtrl)) * imagesSizeFactor;

    return { correctWidth, correctHeight };
  }
};

const getCorrectImageSizeFor360 = (height, imagesDefaultSizes) => {
  const { LUMINA_IMAGE_SIZE } = imagesDefaultSizes;
  const { width: naturalWidth, height: naturalHeight } = LUMINA_IMAGE_SIZE;

  const heightReducedPercentage = height / naturalHeight;
  return { correctWidth: naturalWidth * heightReducedPercentage, correctHeight: height };
};

const getCorrectImageSizeForRTH = (containerWidth, containerHeight, imageWidth, imageHeight) => {
  const containerRatio = containerWidth / containerHeight;
  const imageRatio = imageWidth / imageHeight;
  let imgWidth;
  let imgHeight;

  if (containerRatio === imageRatio) {
    imgWidth = containerWidth;
    imgHeight = containerHeight;
  } else if (containerRatio > imageRatio) {
    imgWidth = containerWidth;
    imgHeight = imageHeight * (containerWidth / imageWidth);
  } else {
    imgWidth = imageWidth * (containerHeight / imageHeight);
    imgHeight = containerHeight;
  }

  return { width: imgWidth, height: imgHeight };
};

export { getCorrectImageSize, getMaxMovementFromPointInImageToFrameCenter, getPointOnResizedImage };
