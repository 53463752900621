import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { globalEventsKeys } from '@web-3d-tool/shared-logic';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './imagePinButton.module.css';
import Button from '../Button';

const ImagePinButton = (props) => {
  const { handleChangeImagePinActive, sdk, isModelCompared } = props;

  // memos
  const getPinActive = useMemo(() => sdk.getIsPinImageActive(), [sdk]);
  const shouldShowPinButton = useMemo(() => !(isModelCompared && sdk.pluginId.includes('_compare')), [
    isModelCompared,
    sdk.pluginId,
  ]);

  // states
  const [isActive, setIsActive] = useState(getPinActive);

  // callbacks
  const handleOnClick = useCallback(() => {
    setIsActive((prevState) => !prevState);
    handleChangeImagePinActive(!isActive);
    sdk.setIsPinImageActive(!isActive);
  }, [handleChangeImagePinActive, isActive, sdk]);

  // effects
  useEffect(() => {
    if (!shouldShowPinButton) {
      sdk.subscribeToEvent(globalEventsKeys.IS_PIN_IMAGE_ACTIVE, ({ pluginId, isPinActive }) => {
        if (pluginId !== sdk.pluginId) {
          handleChangeImagePinActive(isPinActive);
        }
      });
    }
  }, [handleChangeImagePinActive, isModelCompared, sdk, shouldShowPinButton]);

  const className = classNames(styles.pinButton, {
    [styles.pinButtonImage]: !isActive,
    [styles.pinButtonImageActive]: isActive,
  });

  return (
    <>
      {shouldShowPinButton && (
        <Button
          onClick={() => handleOnClick()}
          className={[className]}
          data-test-id={'test-image-pin-button'}
          id={'image-pin-button'}
        ></Button>
      )}
    </>
  );
};

ImagePinButton.propTypes = {
  sourcesArr: PropTypes.array.isRequired,
};

export default ImagePinButton;
